import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/login',
    name:'login',
    component: ()=>import('../views/Login.vue')
  },
  {
    path:'/',
    name:'home',
    component:()=>import('../components/Layout.vue'),
    redirect:'/miner',
    children:[
      // {
      //   path:'index', 
      //   component:()=>import('../views/Index.vue')
      // },
      // {
      //   path:'contribute',
      //   component:()=>import('../views/Contribute.vue')
      // },
      // {
      //   path:'nodes',
      //   component:()=>import('../views/Nodes.vue')
      // },
      // {
      //   path:'farm',
      //   component:()=>import('../views/Farm/Index.vue')
      // },
      // {
      //   path:'lp',
      //   component:()=>import('../views/Lp/Index.vue')
      // },
      // {
      //   path:'ecology',
      //   component:()=>import('../views/Ecology/Index.vue')
      // },
      // {
      //   path:'chain',
      //   component:()=>import('../views/Chain.vue')
      // },
      // {
      //   path:'ntfs',
      //   component:()=>import('../views/NTFS.vue')
      // },
      {
        path:'miner',
        component:()=>import('../views/Miner/Index.vue')
      },
    ]
  }
]

//第一步：将原始的push保存一份
const originPush=VueRouter.prototype.push
const originReplace=VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originReplace.call(this, location).catch(err => err);
};

//路由守卫
// router.beforeEach((to,from,next)=>{
//   if(to.path==='/login'){
//     next()
//   }else{
//     const token=sessionStorage.getItem('token')
//     if(!token){
//       next('/login')
//     }else{
//       next()
//     }
//   }
// })
const router = new VueRouter({
  mode: 'hash',
  // base: '/chain',
  routes
})
// router.beforeEach((to,from,next)=>{
//   next()
// })
export default router
